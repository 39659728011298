import React from "react";
import "./footer.css";
import Logo from "../../../assets/images/logo-philips.png";
import TermsConditionModal from "../modal/termsConditionModal";
import PrivacyModal from "../modal/privacyModal";
import REACT_APP_CAD_CONSUMER_URL from "../utils/constants";

function Footer() {
  return (
    <div id="footer" className="row">
      <div className="col-lg-12">
        <TermsConditionModal></TermsConditionModal>
        <PrivacyModal></PrivacyModal>
        {/* <div className="row">
          <div className="col-lg-8 col-xl-8 col-md-6 col-sm-6 col-6">

          </div>
          <div className="col-lg-2 col-xl-2 col-md-6 col-sm-6 col-6">
            <h3>Community</h3>
            <a href="#">Featured doctors</a>
            <a href="#">The Portal</a>
            <a href="#">Live events</a>
          </div>
          <div className="col-lg-2 col-xl-2 col-md-6 col-sm-6 col-6">
            <h3>Company</h3>
            <a href="#">About us</a>
            <a href="#">Contact us</a>
          </div>
        </div> */}
        <div className="row mt-3">
          <div className="col-lg-8 col-xl-8 col-md-6 col-sm-12 col-12 philips-logo-icon">
            <img src={Logo} alt="Logo" className="img-fluid" />
          </div>
          <div className="col-lg-2 col-xl-2 col-md-6 col-sm-6 col-6">
            <h3>Mobile app</h3>
            <a href={`${REACT_APP_CAD_CONSUMER_URL}`} >Guided health service</a>
            <a href="/#promise">Promise</a>
            <a href="/#technology">Technology</a>

          </div>
          <div className="col-lg-2 col-xl-2 col-md-6 col-sm-6 col-6">
            <h3>HeartPrint</h3>
            {/* <a href="#">About us</a> */}
            {/* <a href="#">Contact us</a> */}
            <a data-bs-toggle="modal" data-bs-target="#termsConditionModal" >Terms & Conditions</a>
            <a data-bs-toggle="modal" data-bs-target="#privacyModal" >Privacy</a>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Footer;