import React, { useEffect } from 'react';
import AOS from 'aos';
import Star from '../../assets/images/star.svg';
import ProfilerBannerImg from '../../assets/images/profilerService.png';
import SignatureBannerImg from '../../assets/images/signatureService.png';
import './services.css';
import TimelineProfiler from '../../common/components/timeline/timelineProfiler';
import TimelineSignature from '../../common/components/timeline/timelineSignature';
import BeatsIconProfiler from '../../assets/images/profilerBeats.svg';
import BeatsIconSignature from '../../assets/images/signatureBeats.svg';
import TimelineMobileProfiler from '../../common/components/timelineMobile/timelineMobileProfiler';
import TimelineMobileSignature from '../../common/components/timelineMobile/timelineMobileSignature';
import REACT_APP_CAD_CONSUMER_URL from '../../common/components/utils/constants';

/* jscpd:ignore-start */
function Services() {
    useEffect(() => {
        AOS.init();
        let tabId = window.location.hash;
        if (tabId === '#signature') {
            const elementSignature = document.getElementById('signature');
            elementSignature?.classList.add("active", "show")
            const elementProfiler = document.getElementById('profiler');
            elementProfiler?.classList.remove("active", "show")
            const elementSignatureTab = document.getElementById('signatureTab');
            elementSignatureTab?.classList.add("active")
            const elementProfilerTab = document.getElementById('profilerTab');
            elementProfilerTab?.classList.remove("active")
        }
    }, []);

    return (
        <div id="services" className='max-width-layout'>
            <section id="about" className="about ">
                <div data-aos="fade-up">
                    <div className="row" data-aos="fade-up" data-aos-delay="200">
                        <div className="col-lg-12">
                            <ul className="nav nav-pills text-center">
                                <li>

                                    <a
                                        className="nav-link active"
                                        data-bs-toggle="pill"
                                        href="#profiler"
                                        id="profilerTab"
                                    >
                                        <div
                                            className="profilerTab"
                                        />
                                        <div>Profiler</div>
                                    </a>
                                </li>
                                <li>

                                    <a
                                        className="nav-link"
                                        data-bs-toggle="pill"
                                        href="#signature"
                                        id="signatureTab"
                                    >
                                        <div
                                            className="signatureTab"
                                        />
                                        <div >Signature</div>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content tab-content-journey">
                                <div className="tab-pane fade show active col-12" id="profiler">
                                <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="journey-info-container">
                                        <h2>Profiler Journey</h2>
                                        <p>
                                        The profiler journey introduces you to the HeartPrint universe, designed as a concise 5-day program where you engage for no more than five minutes daily using just your smartphone. 
                                        </p>
                                        <p>
                                        This journey offers insights into various parameters that gauge your heart's health while at rest, tracks patterns, and presents a heart safety score upon completion.
                                        </p>
                                        <br />
                                        <div className="d-flex" id='try-now'>
                                            <a href={`${REACT_APP_CAD_CONSUMER_URL}`} target="_self" className="btn-try-now scrollto">Try it now</a>
                                        </div>
                                        {/* <p>
                                            It is a 5 day analysis that will keep track the patterns
                                            of your resting heart and also help uncover:
                                        </p> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="row information-points">
                                        <div className="col-xl-6 col-sm-6 col-6 p-0 m-0 d-flex align-items-center flex-column">
            <div className="d-flex align-items-center flex-column">
                <img src={Star} alt="Profiler" className="star-icon" />
                <li id='left'>Resting heart safety score</li>
            </div>
            <br />
            <div className="d-flex align-items-center flex-column">
                <img src={Star} alt="Profiler" className="star-icon" />
                <li>Resilience & reliability metrics</li>
            </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-0 m-0 d-flex align-items-center flex-column " style={{ paddingLeft: '5px', paddingBottom: '10px' }}>

            <div className="d-flex align-items-center flex-column">
                <img src={Star} alt="Profiler" className="star-icon" />
                <li>Progression in your overall health</li>
            </div>
            <br />
            <div className="d-flex align-items-center flex-column">
                <img src={Star} alt="Profiler" className="star-icon" />
                <li>Indicators of changes in your overall health</li>
            </div>
        </div>
                                            
                                           
                                        </div>
                                    </div>
                                    </div>
                                     <br />
                                    <div id="information">
                                        <div className="row">
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 about-desc p-4 m-0'>
                                                <h3>Why analyze your resting heart</h3>
                                                <br />
                                                <p>
                                                Measuring your heart's resting state helps define your baseline heart health and assesses your risk profile. This journey offers an analysis of your resting heart and provides lifestyle recommendations. The profiler is especially beneficial if your daily activities predominantly include:
                                                </p>
                                                <ul className="two-columns">
                                                    <li>Working at a desk</li>
                                                    <li>Listening to music</li>
                                                    <li>Sleeping</li>
                                                    <li>Performing household tasks</li>
                                                    <li>Consuming less healthy food</li>

                                                </ul>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex p-0 m-0'>
                                                <img
                                                    src={ProfilerBannerImg}
                                                    alt="Profiler"
                                                    className="img-fluid w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="beats">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  m-0 beats-image">
                                                <img
                                                id='img1'
                                                    src={BeatsIconProfiler}
                                                    alt="Beats"
                                                    className="img-fluid" 
                                                />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  m-0 beats-content">
                                                <h3>How we do it:</h3>
                                                <p>In evaluating your resting heart, we consider:</p>
                                                <ul>
                                                    <li>· Balance, assessing both physical and mental stress levels; </li>
                                                    <li>· Ageing, indicating the heart's wear and tear; and </li>
                                                    <li>· Stability, measuring how well your vitals maintain equilibrium without excessive exertion. 
                                                    </li>
                                                </ul>
                                                <p>
                                                Based on these factors, we offer a heart safety score that reflects your heart's condition at rest
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="timeline">
                                        <h2 className="small-heading">The process involves measuring your vitals, posing questions,and constructing a resting heart profile based on: </h2>
                                       
                                        <TimelineProfiler />
                                        <TimelineMobileProfiler />
                                        <div className="d-flex" id='try-now'>
                                            <a href={`${REACT_APP_CAD_CONSUMER_URL}`} target="_self" className="btn-try-now scrollto">Try it now</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="signature">
                                <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="journey-info-container">
                                        <h2>Signature Journey</h2>
                                        <p>
                                        The signature journey is the lighthouse to your heart, evaluating how your heart responds and recovers under physical and mental stress. 
                                        </p>
                                        <p>
                                        Structured as a 8-day program, this journey aims to reveal your heart's capacity and the load it bears when you are active or under stress.
                                        </p>
                                        <br />
                                        <div className="d-flex" id='try-now'>
                                            <a href={`${REACT_APP_CAD_CONSUMER_URL}`} target="_self" className="btn-try-now scrollto">Try it now</a>
                                        </div>
                                        {/* <p>
                                            It is a 7 day analysis that will keep track of your reactive heart and also help uncover:
                                        </p> */}
                                        </div>
                                    </div>
                                         <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="row information-points">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-0 m-0 d-flex align-items-center flex-column">
            <div className="d-flex align-items-center flex-column">
                <img src={Star} alt="Profiler" className="star-icon" />
                <li>Reactive heart safety score</li>
            </div>
            <br />
            <div className="d-flex align-items-center flex-column">
                <img src={Star} alt="Profiler" className="star-icon" />
                <li>Burnout potential</li>
            </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-0 m-0 d-flex align-items-center flex-column">
            <div className="d-flex align-items-center flex-column">
                <img src={Star} alt="Profiler" className="star-icon" />
                <li>Endurance & fitness</li>
            </div>
            <br />
            <div className="d-flex align-items-center flex-column">
                <img src={Star} alt="Profiler" className="star-icon" />
                <li>Lifestyle & clinical guidance</li>
            </div>
        </div>
                                            
                                           
                                        </div>
                                      
                                        </div>
                                    </div>

                                    <div id="information">
                                        <div className="row">
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 about-desc p-4 m-0'>
                                                <h3>Why monitor your reactive heart?</h3>
                                                <br />
                                                <p>
                                                Understanding how your heart responds to and recovers from stress, whether physical or emotional, providing valuable insights into your overall heart health. The Signature journey is particularly suited for you if your typical activities include:
                                                </p>
                                                <ul className="multi-column-list">
                                                    <li>Exercising at the gym</li>
                                                    <li>Hiking</li>
                                                    <li>Running</li>
                                                    <li>Enduring long commutes</li>
                                                    <li>Engaging in social events or partying</li>
                                                </ul>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex p-0 m-0'>
                                                <img
                                                    src={SignatureBannerImg}
                                                    alt="Profiler"
                                                    className="img-fluid w-100"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="beats">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  m-0 beats-image">
                                                <img
                                                id='img1'
                                                    src={BeatsIconSignature}
                                                    alt="Beats"
                                                    className="img-fluid" 
                                                />
                                                <br />
                                                <br />
                                                 <p>
                                                 Utilizing data from both resting and reactive heart evaluations, we provide a detailed classification designed to support your activities, with the exception of extreme sports.
                                                </p>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  m-0 beats-content">
                                                <h3>How we do it:</h3>
                                                <p>
                                                In assessing the reactive heart, we examine: 
                                                </p>
                                                <ul>
                                                    <li>· Balance, evaluating your response to physical and mental stress; </li>
                                                    <li>· Ageing, indicating the heart’s wear and tear; </li>
                                                    <li>· Stability, assessing how well your vitals manage under stress; </li>
                                                    <li>· Endurance, reflecting your fitness level; and</li>
                                                    <li>· Trends, identifying evolving health patterns. </li>
                                                </ul>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div id="timeline" >
                                        <h2 className="small-heading">The process involves measuring your vitals, posing questions, and constructing a reactive heart profile based on: </h2>

                                        <TimelineSignature />
                                        <TimelineMobileSignature />

                                        <div className="d-flex">
                                            <a href={`${REACT_APP_CAD_CONSUMER_URL}`}target="_self" className="btn-try-now scrollto">Try it now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

/* jscpd:ignore-end */

export default Services;
